import footer from "../text/footer.json";
import {
  faFacebook,
  faWeixin,
  faWeibo,
  faQq,
  faYelp,
  faYoutube,
  faCcVisa,
  faCcMastercard,
  faCcJcb,
  faCcPaypal,
  faCcAmex,
  faCcDiscover,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function Footer() {
  const inputStyle = {
    background: "#fff",
    width: "74%",
    border: "0",
    borderRadius: "10px 0 0 10px",
    height: "42px",
    padding: "0 5px",
  };
  const buttonStyle = {
    background: "#71e3f5",
    color: "#fff",
    fontWeight: "bold",
    fontSize: "18px",
    width: "20%",
    border: "0",
    height: "40px",
    borderRadius: "0 10px 10px 0",
  };
  const iconStyle = {
    color: "#ffffff",
    height: `40px`,
    width: `40px`,
    cursor: `pointer`,
  };
  const iconList = [
    <FontAwesomeIcon icon={faFacebook} style={iconStyle} />,
    <FontAwesomeIcon icon={faWeixin} style={iconStyle} />,
    <FontAwesomeIcon icon={faWeibo} style={iconStyle} />,
    <FontAwesomeIcon icon={faQq} style={iconStyle} />,
    <FontAwesomeIcon icon={faYelp} style={iconStyle} />,
    <FontAwesomeIcon icon={faYoutube} style={iconStyle} />,
  ];
  const paymentIconList = [
    <FontAwesomeIcon icon={faCcVisa} style={iconStyle} />,
    <FontAwesomeIcon icon={faCcMastercard} style={iconStyle} />,
    <FontAwesomeIcon icon={faCcJcb} style={iconStyle} />,
    <FontAwesomeIcon icon={faCcPaypal} style={iconStyle} />,
    <FontAwesomeIcon icon={faCcAmex} style={iconStyle} />,
    <FontAwesomeIcon icon={faCcDiscover} style={iconStyle} />,
  ];
  const paymentIconItem = paymentIconList.map((icon, index) => (
    <div
      key={index}
      style={{
        flexBasis: `40%`,
        borderRadius: `50%`,
        display: `flex`,
        justifyContent: `center`,
        alignItems: `center`,
      }}
    >
      {icon}
    </div>
  ));
  const footerIcons = iconList.map((icon, index) => (
    <div
      className="footerIconHolder"
      key={index}
      style={{ height: `50px`, width: `50px` }}
    >
      {icon}
    </div>
  ));
  const footerP1Items = footer.map((item, index) => (
    <ul
      key={index}
      style={{
        display: `flex`,
        flexDirection: `column`,

        listStyleType: `none`,
        alignItems: `flex-start`,
        marginLeft: `0`,
        paddingLeft: `0`,
        gap: `5px`,
      }}
    >
      <li style={{ fontWeight: `bold` }}>
        <a style={{ cursor: `pointer` }}>{item.title}</a>
      </li>
      {item.content.map((contentItem, index) => (
        <li key={index}>
          <a style={{ cursor: `pointer` }}>{contentItem}</a>
        </li>
      ))}
    </ul>
  ));
  return (
    <div
      className="footer"
      style={{ display: `flex`, justifyContent: `center`, color: `#fff` }}
    >
      <div
        className="footerWrapper"
        style={{
          width: `1400px`,
          height: `100%`,
          display: `flex`,
          flexDirection: `column`,
        }}
      >
        <div className="footerTop" style={{ flex: `3.5 1 0`, display: `flex` }}>
          <div
            className="footerTopP1"
            style={{
              flexBasis: `37.8%`,
              margin: `40px 0 20px 0`,
              borderRight: `1px solid #b9e5ed`,
              display: `flex`,
              gap: `60px`,
            }}
          >
            {footerP1Items}
          </div>
          <div
            className="footerTopP2"
            style={{
              flexBasis: `30.8%`,
              margin: `40px 0 20px 0`,
              borderRight: `1px solid #b9e5ed`,
              display: `flex`,
              flexDirection: `column`,
              alignItems: `flex-start`,
              marginLeft: `30px`,
              width: `100%`,
            }}
          >
            <p>输入邮箱,订阅悦禾精彩内容</p>
            <div
              className="footerFollow"
              style={{ margin: `15px 0`, width: `90%` }}
            >
              <input
                id="subscribe"
                type="text"
                toggle="输入邮箱，订阅温馨精彩内容"
                placeholder="输入邮箱，订阅温馨精彩内容"
                style={inputStyle}
              />
              <input type="button" value="订阅" style={buttonStyle} />
            </div>
            <div
              className="footerFollow2"
              style={{
                display: `flex`,
                flexWrap: `wrap`,
                alignItems: `center`,
                gap: `10px`,
              }}
            >
              <p
                style={{
                  textTransform: `uppercase`,
                  fontWeight: `bold`,
                  fontSize: `18px`,
                }}
              >
                Follow Us
              </p>
              {footerIcons}
            </div>
          </div>
          <div
            className="footerTopP3"
            style={{
              flexBasis: `31%`,
              margin: `40px 0 20px 0`,
              display: `flex`,
            }}
          >
            <div className="supportPayment" style={{ flex: `1 1 0` }}>
              <p>支持以下支付</p>
              <div
                className="paymentIconWrapper"
                style={{
                  flex: `1 1 0`,
                  display: `flex`,
                  flexWrap: `wrap`,
                  justifyContent: `center`,
                  gap: `12px`,
                }}
              >
                {paymentIconItem}
              </div>
            </div>
            <div className="weixinCode" style={{ flex: `1 1 0` }}>
              <p
                style={{
                  height: `180px`,
                  width: `160px`,
                  backgroundColor: `#fff`,
                  borderRadius: `15px`,
                  color: `#000`,
                }}
              >
                二维码PlaceHolder
              </p>
            </div>
          </div>
        </div>
        <div
          className="footerBottom"
          style={{ flex: `1 1 0`, borderTop: `1px dashed #b9e5ed` }}
        >
          <p style={{ margin: `0`, padding: `0` }}>CopyRight</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
