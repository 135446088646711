import React from "react";
import { storage } from "../firebase/firebase";
import { ref, listAll, getDownloadURL } from "firebase/storage";

const iconRef = ref(storage, "image/certificate/");
const agentRef = ref(storage, "image/agents/");
const sliderRef = ref(storage, "image/slider/");
const limitRef = ref(storage, "image/Limit/");
const hotRef = ref(storage, "image/hot/");

const getCertificate = (setStateImage, setStateIcon) => {
  listAll(iconRef).then((response) => {
    response.prefixes.forEach((prefix) => {
      listAll(prefix).then((prefixRes) => {
        if (prefixRes.items.length >= 2) {
          getDownloadURL(prefixRes.items[1]).then((url) => {
            setStateImage((prev) => [...prev, url]);
          });
          getDownloadURL(prefixRes.items[0]).then((url) => {
            setStateIcon((prev) => [...prev, url]);
          });
        } else {
          setStateIcon((prev) => [...prev, null]);
        }
        // setCertificateList((prev) => [...prev, prefixRes.items]);
      });
    });
  });
};
const getAgent = (setStateImage, setStateName) => {
  listAll(agentRef).then((response) => {
    response.prefixes.forEach((prefix) => {
      listAll(prefix).then((prefixRes) => {
        prefixRes.items.forEach((item) => {
          getDownloadURL(item).then((url) => {
            if (url.includes("png")) {
              setStateImage((prev) => [...prev, url]);
            } else {
              fetch(url)
                .then((response) => response.json())
                .then((data) => {
                  setStateName((prev) => [...prev, data]);
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          });
        });
      });
    });
  });
};
const getSlider = (setState) => {
  listAll(sliderRef).then((response) => {
    response.items.forEach((item) => {
      getDownloadURL(item).then((url) => {
        setState((prev) => [...prev, url]);
      });
    });
  });
};
const getLimitTopicImage = (setState) => {
  listAll(limitRef).then((response) => {
    response.prefixes.forEach((prefix, index) => {
      listAll(prefix).then((prefixRes) => {
        const array = [];
        prefixRes.items.forEach((item) => {
          getDownloadURL(item).then((url) => {
            array.push(url);
            array.sort();
            // const source = {};
            // source[index] = array;
            // console.log(source);
            setState((prev) => ({ ...prev, [index]: array }));
          });
        });
      });
    });
  });
  console.log();
};
const getHotImage = (setState) => {
  listAll(hotRef).then((response) => {
    response.items.forEach((item) => {
      getDownloadURL(item).then((url) => {
        setState((prev) => [...prev, url]);
      });
    });
  });
};
const getImageData = (setState, link) => {
  const reference = ref(storage, link);
  listAll(reference).then((response) => {
    response.items.forEach((item) => {
      getDownloadURL(item).then((url) => {
        setState((prev) => [...prev, url]);
      });
    });
  });
};
export {
  getAgent,
  getCertificate,
  getSlider,
  getLimitTopicImage,
  getHotImage,
  getImageData,
};
