import CountdownTimer from "./CountdownTimer";
import { useState } from "react";
import Topics from "../components/Topics.js";
function Limit(props) {
  const THREE_DAYS_IN_MS = 3 * 24 * 60 * 60 * 1000;
  const NOW_IN_MS = new Date().getTime();
  const DIFFERENCE_IN_TIME = props.date - NOW_IN_MS;
  const dateTimeAfterThreeDays = NOW_IN_MS + THREE_DAYS_IN_MS;
  const [optionHover, setOptionHover] = useState(null);
  const [topicIndex, setTopicIndex] = useState(0);
  const PINK = "#fec0cb";
  const BLUE = "#4dd7ed";
  const limitImageObjectZero = require.context("../image/Limit/0", true);
  const limitImageListZero = limitImageObjectZero.keys().map((image) => limitImageObjectZero(image));
  const limitImageObjectOne = require.context("../image/Limit/1", true);
  const limitImageListOne = limitImageObjectOne.keys().map((image) => limitImageObjectOne(image));
  const limitImageObjectTwo = require.context("../image/Limit/2", true);
  const limitImageListTwo = limitImageObjectTwo.keys().map((image) => limitImageObjectTwo(image));
  const limitImageObjectThree = require.context("../image/Limit/3", true);
  const limitImageListThree = limitImageObjectThree.keys().map((image) => limitImageObjectThree(image));
  const limitImageObjectFour = require.context("../image/Limit/4", true);
  const limitImageListFour = limitImageObjectFour.keys().map((image) => limitImageObjectFour(image));
  const limitImageObjectFive = require.context("../image/Limit/5", true);
  const limitImageListFive = limitImageObjectFive.keys().map((image) => limitImageObjectFive(image));
  const limitImageObjectSix = require.context("../image/Limit/6", true);
  const limitImageListSix = limitImageObjectSix.keys().map((image) => limitImageObjectSix(image));
  const limitImageObjectSeven = require.context("../image/Limit/7", true);
  const limitImageListSeven = limitImageObjectSeven.keys().map((image) => limitImageObjectSeven(image));
  const limitImageLists = [limitImageListZero,limitImageListOne,limitImageListTwo,limitImageListThree,limitImageListFour,limitImageListFive,limitImageListSix,limitImageListSeven];

  const optionsItemsList = [
    {
      name: props.topicNames === null ? null : props.topicNames[0],
      // imageList: imageZero.keys().map((image) => imageZero(image)),
    },
    {
      name: props.topicNames === null ? null : props.topicNames[1],
      // imageList: imageOne.keys().map((image) => imageOne(image)),
    },
    {
      name: props.topicNames === null ? null : props.topicNames[2],
      // imageList: imageTwo.keys().map((image) => imageTwo(image)),
    },
    {
      name: props.topicNames === null ? null : props.topicNames[3],
      // imageList: imageThree.keys().map((image) => imageThree(image)),
    },
    {
      name: props.topicNames === null ? null : props.topicNames[4],
      // imageList: imageFour.keys().map((image) => imageFour(image)),
    },
    {
      name: props.topicNames === null ? null : props.topicNames[5],
      // imageList: imageFive.keys().map((image) => imageFive(image)),
    },
    {
      name: props.topicNames === null ? null : props.topicNames[6],
      // imageList: imageSix.keys().map((image) => imageSix(image)),
    },
    {
      name: props.topicNames === null ? null : props.topicNames[7],
      // imageList: imageSeven.keys().map((image) => imageSeven(image)),
    },
  ];
  const headLineH2Style = {
    width: `100%`,
    textAlign: `center`,
    borderBottom: `1px solid #4dd7ed`,
    lineHeight: `0.1em`,
    margin: `10px 0 20px`,
  };
  const H2SpanStyle = {
    background: `#fff`,
    padding: `0 20px`,
  };
  const optionsItems = optionsItemsList.map((item, index) => (
    <div
      key={index}
      style={{
        fontSize: `20px`,
        fontWeight: `bold`,
        cursor: `pointer`,
        color: `${optionHover === index ? `#fec0cb` : `#000000`}`,
      }}
      onMouseEnter={() => {
        setOptionHover(index);
        setTopicIndex(index);
      }}
      onMouseLeave={() => setOptionHover(null)}
    >
      {item.name}
    </div>
  ));
  return (
    <div
      className="limit"
      style={{ display: `flex`, flexDirection: `column`, alignItems: `center` }}
    >
      <div
        className="headLine"
        style={{
          width: `100%`,
          fontSize: `42px`,
          paddingTop: `20px`,
          color: `#4dd7ed`,
        }}
      >
        <div style={headLineH2Style}>
          <span style={H2SpanStyle}>限时抢购</span>
        </div>
      </div>
      <div
        className="limitMiddle"
        style={{
          display: `flex`,
          justifyContent: `space-between`,
          alignItems: `center`,
          width: `1400px`,
          height: `120px`,
        }}
      >
        <div
          className="countDown"
          style={{
            display: `flex`,
            flexDirection: `row`,
            border: `1px solid #fec0cb`,
            borderRadius: `10px`,
            maxHeight: `50%`,
          }}
        >
          <div
            style={{
              padding: `0 30px 0 30px`,
              backgroundColor: `#fec0cb`,
              borderRadius: `10px 0 0 10px`,
              maxHeight: `100%`,
              display: `flex`,
              justifyContent: `center`,
              alignItems: `center`,
            }}
          >
            <span
              style={{
                color: `white`,
                fontSize: `17px`,
                maxHeight: `100%`,
                padding: `0`,
              }}
            >
              离秒杀结束还有
            </span>
          </div>
          <CountdownTimer targetDate={props.date} />
        </div>
        <div
          className="options"
          style={{
            display: `flex`,
            flexDirection: `row`,
            justifyContent: `space-around`,
            width: `40%`,
          }}
        >
          {optionsItems}
          <div
            style={{
              fontSize: `20px`,
              fontWeight: `bold`,
              cursor: `pointer`,
            }}
          >
            更多
          </div>
        </div>
      </div>
      <div
        className="cards"
        style={{
          backgroundColor: `#4dd7ed`,
          flex: `1`,
          width: `100%`,
          maxHeight: `100%`,
          justifyContent: `center`,
          display: `flex`,
        }}
      >
        <div
          className="cardsWrapper"
          style={{ height: `100%`, width: `1400px`, display: `flex` }}
        >
          <Topics
            images={limitImageLists[topicIndex]}
            info={props.topic ? Object.values(props.topic)[topicIndex] : null}
            price={props.price ? Object.values(props.price)[topicIndex] : null} 
            topicIndex = {topicIndex}
          />
        </div>
      </div>
    </div>
  );
}

export default Limit;
