import "./App.css";
import Header from "./components/Header";
import SideMenu from "./components/SideMenu";
import Footer from "./components/Footer";
import { useState, useEffect, useRef } from "react";
import { useViewport } from "./hooks/useViewport";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import { getText } from "./components/FetchText";
import PlanDetail from "./components/PlanDetail";
import Visa from "./components/Visa";
function App() {
  const [headerItem, setHeaderItem] = useState([]);
  const { width } = useViewport();
  const breakpoint = 620;
  const initialized = useRef(false);
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      getText(setHeaderItem, `headerTabItemList`);
    }
  }, []);

  return (
    <Router>
      <div className="App">
        <Header tab={headerItem} />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/plan_detail/:category/:index" element={<PlanDetail />} />
          <Route path="/visa" element={<Visa />} />
        </Routes>
        <Footer />
        {width < breakpoint ? null : <SideMenu />}
      </div>
    </Router>
  );
}

export default App;
