import { useEffect, useState, useRef } from "react";
import { ChevronLeft, ChevronRight } from "react-feather";
import { Link } from "react-router-dom";
import pDetail from "../text/planDetail.json";
function Slider({ slides }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const timerRef = useRef();
  useEffect(() => {
    updateIndex();

    return () => clearInterval(timerRef.current);
  }, []);

  const updateIndex = () => {
    timerRef.current = setInterval(() => {
      setCurrentIndex((count) => count + 1);
    }, 2000);
  };

  const origCount = (index) => {
    clearInterval(timerRef.current);
    setCurrentIndex(index);
  };

  const chevronStyle = {
    borderRadius: `50%`,
    borderStyle: `none`,
    height: `60px`,
    width: `60px`,
    backgroundColor: `rgba(255, 255, 255, 0.8)`,
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
  };
  const sectionList = slides.map((url, index) => (
    <section
      key={index}
      style={{
        backgroundImage: `url(${url.url})`,
        transform: `translateX(-${(currentIndex % slides.length) * 100}%)`,
        transition: `transform 0.5s ease-out`,
        backgroundSize: `cover`,
      }}
    >
      <Link
        to={`/plan_detail/slider/${pDetail.slider[index].id}`}
        target="_blank"
        style={{ height: `100%`, width: `100%` }}
      ></Link>
    </section>
  ));
  const dotSelectorList = slides.map((_, index) => (
    <div
      key={index}
      style={{
        backgroundColor: `${
          currentIndex % slides.length === index ? `#0095ff` : `#C5C6D0`
        }`,
        height: `10px`,
        width: `70px`,
        borderRadius: `10% / 50%`,
        borderStyle: `none`,
        caretColor: `transparent`,
        opacity: `0.6`,
        //transition: `background-color 0.5s ease, border-radius 0.5s ease-out`,
      }}
      onMouseOver={() => origCount(index)}
      onMouseOut={updateIndex}
    >
    </div>
  ));
  const NextPage = () => {
    console.log(currentIndex);
    currentIndex >= slides.length - 1
      ? setCurrentIndex(0)
      : setCurrentIndex(currentIndex + 1);
  };
  const PreviousPage = () => {
    currentIndex <= 0
      ? setCurrentIndex(slides.length - 1)
      : setCurrentIndex(currentIndex - 1);
  };

  return (
    <div className="slider">
      <div className="imageContainer">{sectionList}</div>
      {/* <div className="sliderButton">
        <button style={chevronStyle} onClick={PreviousPage}>
          <ChevronLeft size={40} />
        </button>
        <button style={chevronStyle} onClick={NextPage}>
          <ChevronRight size={40} />
        </button>
      </div> */}
      <div className="dotSelector">{dotSelectorList}</div>
    </div>
  );
}

export default Slider;
