import { useState } from "react";
import pDetail from "../text/planDetail.json";
import { Link } from "react-router-dom";
function Topics({ images, info, price, topicIndex }) {
  //images is a list of image source
  //info is a list of list of string
  const [topicHoverIndex, setTopicHoverIndex] = useState(null);
  const cardItems = images
    ? images.map((image, index) => (
        <li key={index} style={{ minWidth: `300px`, minHeight: `300px` }}>
          <div
            style={{
              position: `relative`,
              display: `flex`,
              flexDirection: `column`,
              minWidth: `100%`,
              maxWidth: `100%`,
            }}
          >
            <img
              src={image === null ? null : image}
              style={{ height: `250px`, width: `100%`, objectFit: `cover` }}
            ></img>
            <div
              style={{
                position: `absolute`,
                bottom: `0px`,
                width: `100%`,
                minHeight: `20%`,
                maxHeight: `20%`,
                backgroundColor: `rgba(0,0,0, 0.5)`,
                textAlign: `center`,
              }}
            >
              <p
                style={{
                  fontStyle: `normal`,
                  fontWeight: `bold`,
                  fontSize: `22px`,
                  color: `#fec0cb`,
                  margin: `0`,
                }}
              >
                ${price ? price[index] : null}
              </p>
            </div>
          </div>
          <div
            style={{
              backgroundColor: `#ffffff`,
              width: `100%`,
              maxWidth: `100%`,
              fontSize: `1px`,
              paddingBottom: `10px`,
            }}
          >
            <Link
              to={`/plan_detail/limit/${pDetail.limit[(topicIndex*4)+index].id}`}
              target="_blank"
              style={{
                display: `flex`,
                flexDirection: `column`,
                alignItems: `center`,
                cursor: `pointer`,
              }}
              onMouseEnter={() => setTopicHoverIndex(index)}
              onMouseLeave={() => setTopicHoverIndex(null)}
            >
              <span
                style={{
                  fontSize: `13px`,
                  color: `${topicHoverIndex === index ? `#fec0cb` : `#000000`}`,
                  padding: `10px`,
                }}
              >
                {info ? info[index] : null}
              </span>
              <span
                style={{
                  fontSize: `16px`,
                  fontWeight: `bold`,
                  backgroundColor: `#fec0cb`,
                  width: `80px`,
                  margin: `10px`,
                  borderRadius: `15%/50%`,
                  color: `#ffffff`,
                  padding: `5px`,
                }}
              >
                马上抢购
              </span>
            </Link>
          </div>
        </li>
      ))
    : null;
  return (
    <div
      style={{
        height: `100%`,
        width: `100%`,
        margin: `0`,
        padding: `0`,
        display: `flex`,
        justifyContent: `center`,
        alignItems: `center`,
      }}
    >
      <ul
        style={{
          listStyleType: `none`,
          display: `flex`,
          flexDirection: `row`,
          justifyContent: `space-between`,
          alignItems: `center`,
          maxHeight: `100%`,
          minHeight: `100%`,
          margin: `0`,
          padding: `0`,
          width: `100%`,
          gap: `12px`,
        }}
      >
        {images === null ? null : cardItems}
      </ul>
    </div>
  );
}

export default Topics;
