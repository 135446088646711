import { useState } from "react";
import { Link } from "react-router-dom";
import pDetail from "../text/planDetail.json";
function ThemeItems({ images, itemInfo }) {
  const [themeItemHover, setThemeItemHover] = useState(0);
  const themeItemsWrapperStyle = {
    listStyleType: `none`,
    display: `flex`,
    flexDirection: `row`,
    justifyContent: `center`,
    alignItems: `center`,
    maxHeight: `100%`,
    minHeight: `100%`,
    margin: `0`,
    padding: `0`,
    width: `1400px`,
    gap: `12px`,
  };
  const diamondStyle = {
    height: `80px`,
    width: `80px`,
    transform: `rotate(45deg)`,
    border: `2px solid #fff`,
    right: `40%`,
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    borderRadius: `20px`,
    cursor: `pointer`,
  };
  const orderNowStyle = {
    transform: `rotate(-45deg)`,
    display: `flex`,
    alignItems: `center`,
    flexDirection: `column`,
    justifyContent: `center`,
    color:`inherit`
  };
  // const orderNow = (
  //   <div className="diamond" style={diamondStyle}>
  //     <a style={orderNowStyle}>
  //       <p style={{ margin: `0`, padding: `0` }}>立刻</p>
  //       <p style={{ margin: `0`, padding: `0` }}>订购</p>
  //     </a>
  //   </div>
  // );
  const themeItemList =
    images.length === 0
      ? null
      : images.map((image, index) => (
          <div
            className="themeItem"
            key={index}
            style={{
              position: `relative`,
              height: `500px`,
              flex: `${themeItemHover === index ? `1.5` : `0.5`}`,
              transition: `flex 0.3s ease-out`,
              boxShadow: `${
                themeItemHover === index ? `3px 3px 3px #888888` : `none`
              }`,
              color: `#fff`,
            }}
            onMouseEnter={() => setThemeItemHover(index)}
          >
            <img
              src={image}
              style={{
                minHeight: `100%`,
                minWidth: `100%`,
                height: `100%`,
                width: `100%`,
                objectFit: `none`,
              }}
            ></img>
            <div
              style={{
                top: `25%`,
                right: `${themeItemHover === index ? `38%` : `45%`}`,
                position: `absolute`,
                display: `flex`,
                flexDirection: `${themeItemHover === index ? `column` : `row`}`,
                alignItems: `center`,
                writingMode: `${themeItemHover === index ? `` : `vertical-rl`}`,
                textOrientation: `${themeItemHover === index ? `` : `upright`}`,
              }}
            >
              <span
                style={{
                  fontSize: `${themeItemHover === index ? `40px` : `18px`}`,
                }}
              >
                {itemInfo.length === 0 ? null : itemInfo[index].titleOne}
              </span>
              <span>{themeItemHover === index ? "" : "·"}</span>
              <span
                style={{
                  fontWeight: `${themeItemHover === index ? `bold` : `normal`}`,
                  fontSize: `${themeItemHover === index ? `50px` : `18px`}`,
                }}
              >
                {itemInfo.length === 0 ? null : itemInfo[index].titleTwo}
              </span>
            </div>
            <div
              className="diamondHolder"
              style={{
                position: `absolute`,
                bottom: `15%`,
                width: `100%`,
                display: `flex`,
                flexDirection: `row`,
                justifyContent: `center`,
                gap: `40px`,
              }}
            >
              <div
                className="diamond"
                style={{
                  height: `80px`,
                  width: `80px`,
                  transform: `rotate(45deg)`,
                  border: `2px solid #fff`,
                  right: `40%`,
                  display: `flex`,
                  justifyContent: `center`,
                  alignItems: `center`,
                  borderRadius: `20px`,
                }}
              >
                <div
                  style={{
                    transform: `rotate(-45deg)`,
                    display: `flex`,
                    alignItems: `center`,
                  }}
                >
                  <b style={{ fontSize: `28px` }}>
                    {itemInfo.length === 0 ? null : itemInfo[index].days}
                  </b>
                  <p>日</p>
                </div>
              </div>
              {themeItemHover === index ? (
                <div className="diamond" style={diamondStyle}>
                  <Link
                    to={`/plan_detail/theme/${pDetail.theme[index].id}`}
                    target="_blank"
                    style={orderNowStyle}
                  >
                    <p style={{ margin: `0`, padding: `0` }}>立刻</p>
                    <p style={{ margin: `0`, padding: `0` }}>订购</p>
                  </Link>
                </div>
              ) : null}
            </div>
          </div>
        ));

  return (
    <div className="themeItemsWrapper" style={themeItemsWrapperStyle}>
      {themeItemList}
    </div>
  );
}

export default ThemeItems;
