import "./flippable-card.css";
import Card from "./card/card";
import { CSSTransition } from "react-transition-group";
import { useState, useRef } from "react";
function FlippableCard({info, image}) {
  const [showFront, setShowFront] = useState(true);
  //console.log(image)
  const nodeRef = useRef(null);
  return (
    <div className="flippable-card-container" >
      <CSSTransition in={showFront} timeout={300} classNames='flip' nodeRef={nodeRef}>
        <Card
          onClick={() => {
            setShowFront((v)=>!v);
          }}
          info={info}
          image={image}
          nodeRef = {nodeRef}
        />
        
      </CSSTransition>
    </div>
  );
}

export default FlippableCard;
