import contactText from "../text/contact.json";
import certificate from "../text/certificate.json";
import { Phone } from "react-feather";
import { useState } from "react";
import {certificateImage} from "./ImageRequire";
function Contact(props) {
  const [hoverIndex, setHoverIndex] = useState(null);
  //const certificate = sliderImageObject.keys().map((image) => sliderImageObject(image));
  const agentImage = require.context("../image/contact/agent", true);
  const agentImageList = agentImage.keys().map((image) => agentImage(image));
  const certificateList = certificateImage.map((pair, index) => (
    
    <div
      key={index}
      style={{
        flexBasis: `calc(100% / ${certificate.length})`,
        position: `relative`,
      }}
    >
      <img
        src={pair.keys().map((image) => pair(image))[0]}
        style={{
          height: `75px`,
          width: `100%`,
          objectFit: `contain`,
          paddingTop: `10px`,
        }}
        onMouseEnter={() => setHoverIndex(index)}
        onMouseLeave={() => setHoverIndex(null)}
      ></img>
      {
      
      hoverIndex === index ? (
        <div
          style={{
            position: `absolute`,
            width: `600px`,
            height: `400px`,
            top: `-400px`,
            left: `-230px`,
            backgroundColor: `#fff`,
            boxShadow: `5px 5px 5px #aaaaaa`,
          }}
        >
          <img
            src={pair.keys().map((image) => pair(image))[1]}
            style={{
              height: `90%`,
              width: `90%`,
              objectFit: `cover`,
              paddingTop: `10px`,
            }}
          ></img>
        </div>
      ) : null}
      
    </div>
    
  ));
  const nameStyle = {
    color: "#000",
    fontSize: "14px",
    display: "block",
    textTransform: "uppercase",
    lineHeight: "1.3",
    padding: `0`,
  };
  const imageListItems = agentImageList.map((image, index) => (
    <div
      key={index}
      style={{
        display: `flex`,
        flexDirection: `column`,

        justifyContent: `center`,
      }}
    >
      <img
        src={image}
        style={{
          height: `100px`,
          width: `100px`,
          objectFit: `cover`,
          objectPosition: `top`,
          backgroundColor:
          contactText.agents[index] === undefined
              ? null
              : contactText.agents[index].color,
          borderRadius: `50%`,
          padding: `10px`,
        }}
      ></img>
      <span style={nameStyle}>
        {contactText.agents[index] === undefined
          ? null
          : contactText.agents[index].firstName}
      </span>
      <span style={nameStyle}>
        {contactText.agents[index] === undefined
          ? null
          : contactText.agents[index].lastName}
      </span>
    </div>
  ));
  const contactWrapperStyle = {
    display: `flex`,
    height: `100%`,
    width: `1400px`,
    justifyContent: `center`,
    flexDirection: `column`,
    gap: `20px`,
  };
  const contactHeadLineStyle = {
    display: `flex`,
    flexDirection: `column`,
    gap: `20px`,
  };
  const titleStyle = {
    color: "rgb(254, 192, 203)",
    fontSize: "46px",
    fontWeight: "normal",
    textAlign: "center",
    margin: `0`,
    padding: `0`,
  };
  const phoneNumberStyle = {
    color: "#838383",
    fontSize: "24px",
    display: "inline-block",
  };
  return (
    <div
      className="contact"
      style={{ display: `flex`, justifyContent: `center` }}
    >
      <div className="contactWrapper" style={contactWrapperStyle}>
        <div className="contactHeadLine" style={contactHeadLineStyle}>
          <h4 style={titleStyle}>{contactText.header.title}</h4>
          <div style={{ display: `flex`, justifyContent: `center` }}>
            <span
              style={{
                padding: `8px`,
                borderRadius: `50%`,
                backgroundColor: `rgb(254, 192, 203)`,
                display: `flex`,
                alignItems: `center`,
              }}
            >
              <Phone size={15} color="#fff" />
            </span>
            <span style={phoneNumberStyle}>
              {contactText.header.phoneNumber}
            </span>
          </div>
        </div>
        <div style={{ display: `flex`, justifyContent: `center`, gap: `50px` }}>
          {imageListItems}
        </div>
        <div
          style={{
            display: `flex`,
            justifyContent: `center`,
            width: `100%`,
            borderTop: `1px dashed gray`,
            alignItems: `center`,
          }}
        >
          {certificateList}
        </div>
      </div>
    </div>
  );
}

export default Contact;
