// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBUpdUxm_jMLJVdDq9bo3OSVxm9C1xFbWk",
  authDomain: "family-travel-test.firebaseapp.com",
  projectId: "family-travel-test",
  storageBucket: "family-travel-test.appspot.com",
  messagingSenderId: "395544382367",
  appId: "1:395544382367:web:28009438adb14ee5bc0baa",
  measurementId: "G-D7BHWNWEKK",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const storage = getStorage(app);

export { database, storage };
