import { useState } from "react";
import { useViewport } from "../hooks/useViewport";
import * as FaIcons from "react-icons/fa";
import { Link } from "react-router-dom";
import pDetail from "../text/planDetail.json";
function Bundles({ images }) {
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [slideIndex, setSlideIndex] = useState(0);
  const { width } = useViewport();
  const [currentIndex, setCurrentIndex] = useState(null);
  const bundleContainerStyle = {
    listStyleType: `none`,
    display: `flex`,
    flexDirection: `row`,
    justifyContent: `center`,
    alignItems: `center`,
    maxHeight: `100%`,
    minHeight: `100%`,
    margin: `0`,
    padding: `0`,
    width: `1400px`,
    gap: `12px`,
  };
  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50;

  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };
  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);
  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe || isRightSwipe) console.log(slideIndex);
    isLeftSwipe
      ? setSlideIndex(slideIndex >= images.length ? 0 : slideIndex + 1)
      : setSlideIndex(slideIndex <= 1 ? images.length : slideIndex - 1);
    // add your conditional logic here
  };
  const clickChevronLeft = () => {
    setSlideIndex(slideIndex <= 1 ? images.length : slideIndex - 1);
  };
  const clickChevronRight = () => {
    setSlideIndex(slideIndex >= images.length ? 0 : slideIndex + 1);
  };
  const breakpoint = 620;
  const bundleList = images.map((url, index) => (
    <Link
      to={`/plan_detail/bundle/${pDetail.bundle[index].id}`}
      target="_blank"
      style={{ height: `80%`, width: `100%` }}
      key={index}
    >
      <div
        className="bundleItem"
        style={{
          backgroundImage: `url(${url.url})`,
          height: `100%`,
          flexBasis: `25%`,
          transform: `${currentIndex === index ? `scale(1.2)` : `scale(1)`}`,
          transition: `transform 0.5s ease`,
        }}
        onMouseEnter={() => setCurrentIndex(index)}
        onMouseOut={() => setCurrentIndex(null)}
      ></div>
    </Link>
  ));
  const sectionList = images.map((url, index) => (
    <section
      key={index}
      style={{
        backgroundImage: `url(${url.url})`,
        height: `80%`,
        transform: `translateX(-${(slideIndex % images.length) * 100}%)`,
        transition: `transform 0.5s ease-out`,
        backgroundSize: `cover`,
        flexBasis: `100%`,
        display: `flex`,
        justifyContent: `center`,
        alignItems: `center`,
      }}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
    ></section>
  ));
  const mobileBundle = (
    <div
      style={{
        width: `100vw`,
        height: `140px`,
        position: `relative`,
        caretColor: `transparent`,
      }}
    >
      <div
        style={{
          top: `30%`,
          fontSize: `2rem`,
          position: `absolute`,
          zIndex: `10`,
          display: `flex`,
          width: `100%`,
          justifyContent: `space-between`,
          color: `rgba(0, 0, 0,0.5)`,
        }}
      >
        <FaIcons.FaChevronCircleLeft onClick={clickChevronLeft} />
        <FaIcons.FaChevronCircleRight onClick={clickChevronRight} />
      </div>

      <div
        style={{
          width: `400%`,
          height: `100%`,
          display: `flex`,
          overflow: `hidden`,
        }}
      >
        {sectionList}
      </div>
    </div>
  );
  return width < breakpoint ? (
    mobileBundle
  ) : (
    <div
      className="bundles"
      style={{ display: `flex`, justifyContent: `center` }}
    >
      <div className="bundleContainer" style={bundleContainerStyle}>
        {bundleList}
      </div>
    </div>
  );
}
export default Bundles;
