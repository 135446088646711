import ThemeItems from "./ThemeItems";
import itemInfo from "../text/themeItem.json";
function Theme() {
  const imageZero = require.context("../image/theme", true);

  const imageList = imageZero.keys().map((image) => imageZero(image));
  const H2SpanStyle = {
    fontSize: `42px`,
  };
  return (
    <div
      className="theme"
      style={{
        display: `flex`,
        flexDirection: `column`,
        alignItems: `center`,
        gap: `40px`,
      }}
    >
      <div
        className="themeHeadLine"
        style={{
          display: `flex`,
          flexDirection: `column`,
          alignItems: `center`,
        }}
      >
        <span>倾一颗热爱旅游的心·独家打造</span>
        <span style={H2SpanStyle}>温馨主题旅行</span>
      </div>
      <div
        className="themeItems"
        style={{
          display: `flex`,
          justifyContent: `center`,
        }}
      >
        <ThemeItems images={imageList} itemInfo={itemInfo} />
      </div>
    </div>
  );
}

export default Theme;
