import xiaohongshu from "../icon/xiaohongshu.svg";
import wechat from "../icon/wechat.svg";
import customer from "../icon/customer.svg";
import feedback from "../icon/feedback.svg";
import { useState, useEffect } from "react";
import { ChevronUp } from "react-feather";
function SideMenu() {
  const [sideMenuHover, setSideMenuHover] = useState(null);
  const [backToTop, setBackToTop] = useState(false);
  // useEffect(() => {
  //   window.addEventListener("scroll", () => {
  //     if (window.scrollY > 100) {
  //       setBackToTop(true);
  //     } else {
  //       setBackToTop(false);
  //     }
  //   });
  // }, []);
  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const sideMenuStyle = {
    position: `fixed`,
    zIndex: `1`,
    top: `200px`,
    right: `0`,
    display: `table`,
    flexDirection: `column`,
    backgroundColor: `rgba(0,0,0,0.6)`,
    height: `500px`,
    width: `75px`,
    borderRadius: `50%/7%`,
    justifyContent: `space-around`,
    alignItems: `center`,
    paddingTop: `30px`,
  };
  const sideMenuItemsList = [
    {
      name: "客服热线",
      icon: customer,
      hover: <div style={{ color: `white` }}>(123)456-7890</div>,
    },
    {
      name: "微信",
      icon: wechat,
      hover: <div style={{ color: `white` }}>微信二维码Place Holder</div>,
    },
    {
      name: "小红书",
      icon: xiaohongshu,
      hover: <div style={{ color: `white` }}>小红书二维码Place Holder</div>,
    },
    {
      name: "反馈",
      icon: feedback,
      hover: <div></div>,
    },
  ];
  const sideMenuItems = sideMenuItemsList.map((item, index) => (
    <div
      key={index}
      onMouseEnter={() => setSideMenuHover(index)}
      onMouseLeave={() => setSideMenuHover(null)}
      style={{ display: `table-row`, fontSize: `18px` }}
    >
      <a href={item.name} style={{ color: `white`, textDecoration: `none` }}>
        {item.name}
      </a>
      <div className="sideMenuIconWrapper">
        <img src={item.icon} style={{ width: `80%`, height: `auto` }} />
        {sideMenuHover === index ? item.hover : <div></div>}
      </div>
    </div>
  ));
  return (
    <div className="sideMenu" style={sideMenuStyle}>
      {sideMenuItems}
      <div
        style={{
          display: `table-row`,
          color: `${backToTop ? `#4dd7ed` : `#ffffff`}`,
          cursor: `pointer`,
          caretColor: `transparent`,
        }}
        onClick={scrollUp}
        className="backToTop"
        onMouseEnter={() => setBackToTop(true)}
        onMouseLeave={() => setBackToTop(false)}
      >
        <a>返回顶部</a>
        <ChevronUp />
      </div>
    </div>
  );
}

export default SideMenu;
