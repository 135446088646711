import Slider from "../components/Slider";
import Bundles from "../components/Bundles";
import Limit from "../components/Limit";
import Hot from "../components/Hot";
import Theme from "../components/Theme";
import Customize from "../components/Customize";
import AboutUs from "../components/AboutUs";
import BuyingOptions from "../components/BuyingOptions";
import Contact from "../components/Contact";
import { useState, useEffect, useRef } from "react";
import { useViewport } from "../hooks/useViewport";
import limitTopicNames from "../text/limitTopicNames.json";
import limitDate from "../text/limitDate.json";
import limitPrice from "../text/limitPrice.json";
import limitTopics from "../text/limitTopics.json";
import {
  getAgent,
  getCertificate,
  getSlider,
  getLimitTopicImage,
  getHotImage,
  getImageData,
} from "../components/FetchData";
import {
  getLimitInfo,
  getLimitDate,
  getHotNumbers,
  getHotTopics,
  getText,
} from "../components/FetchText";
function Home() {
  //const [certificateList, setCertificateList] = useState([]);
  //const [certificateIconList, setCertificateIconList] = useState([]);
  //const [agentImage, setAgentImage] = useState([]);
  //const [agentName, setAgentName] = useState([]);
  //const [limitTopicNames, setLimitTopicNames] = useState(null);
  //const [limitTopics, setLimitTopics] = useState(null);
 // const [limitPrice, setLimitPrice] = useState(null);
  //const [limitImage, setLimitImage] = useState([]);
  //const [limitDate, setLimitDate] = useState(0);
  //const [hotImage, setHotImage] = useState([]);
  //const [hotTopics, setHotTopics] = useState([]);
  //const [hotNumbers, setHotNumbers] = useState(null);
  //const [themeImage, setThemeImage] = useState([]);
  //const [themeText, setThemeText] = useState([]);
  //const [sliderImage, setSliderImage] = useState([]);
  const { width } = useViewport();
  const breakpoint = 620;
  const sliderImageObject = require.context("../image/slider", true);
  const sliderImage = sliderImageObject.keys().map((image) => sliderImageObject(image));
  const slider = [
    { url: sliderImage[0], title: "bamboo" },
    { url: sliderImage[1], title: "flowers" },
    { url: sliderImage[2], title: "mountain" },
    { url: sliderImage[3], title: "water" },
  ];
  const initialized = useRef(false);
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      //getCertificate(setCertificateList, setCertificateIconList);
      //getAgent(setAgentImage, setAgentName);
      //getSlider(setSliderImage);
      //getLimitInfo(setLimitTopicNames, setLimitTopics, setLimitPrice);
      //getLimitTopicImage(setLimitImage);
      //getLimitDate(setLimitDate);
      //getHotImage(setHotImage);
      //getHotNumbers(setHotNumbers);
      //getHotTopics(setHotTopics);
      //getImageData(setThemeImage, "image/theme/");
      //getText(setThemeText, `themeItem`);
    }
  }, []);
  return (
    <div>
      <Slider slides={slider} />
      <Bundles images={slider} />
      <Limit
        topicNames={limitTopicNames}
        topic={limitTopics}
        price={limitPrice}
        date={new Date(limitDate.limitDate).getTime()}
      />
      <Hot />
      {/* <div className="explore">this is explore</div> */}
      <Theme  />
      <Customize />
      <AboutUs />
      <BuyingOptions />
      <Contact/>
    </div>
  );
}
export default Home;
