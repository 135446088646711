import HotTopics from "./HotTopics";
import topics from "../text/hotTopics.json";
import price from "../text/hotPrice.json";
import numbers from "../text/hotNumber.json";
function Hot(props) {
  const imageZero = require.context("../image/hot", true);
  const imageList = imageZero.keys().map((image) => imageZero(image));
  const H2SpanStyle = {
    fontSize: `42px`,
    paddingTop: `20px`,
    paddingRight: `20px`,
  };
  return (
    <div
      className="hot"
      style={{
        display: `flex`,
        flexDirection: `column`,
        alignItems: `center`,
        alignItems: `center`,
      }}
    >
      <div className="hotTitle" style={{ paddingTop: `20px` }}>
        <span style={H2SpanStyle}>热卖排行</span>
        <span className="hotIcon">HOT</span>
      </div>

      <div
        className="hotTopicsWrapper"
        style={{
          display: `flex`,
          justifyContent: `center`,
          minHeight: `100%`,
          maxHeight: `100%`,
        }}
      >
        <HotTopics
          images={imageList}
          info={topics}
          price={price}
          ratings={numbers.rating}
          purchase={numbers.purchase}
        />
      </div>
    </div>
  );
}

export default Hot;
