import React from "react";
import { database } from "../firebase/firebase";
import { ref, child, get } from "firebase/database";

const dbRef = ref(database);
const getLimitTopicNames = (setState) => {
  get(child(dbRef, `limitTopicNames`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        setState(snapshot.val());
      } else {
        console.log("no data available");
      }
    })
    .catch((error) => {
      console.error(error);
    });
};
const getLimitTopics = (setState) => {
  get(child(dbRef, `limitTopics`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        setState(snapshot.val());
      } else {
        console.log("no data available");
      }
    })
    .catch((error) => {
      console.error(error);
    });
};
const getLimitPrice = (setState) => {
  get(child(dbRef, `limitPrice`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        setState(snapshot.val());
      } else {
        console.log("no data available");
      }
    })
    .catch((error) => {
      console.error(error);
    });
};
const getLimitInfo = (setNameState, setTopicState, setPriceState) => {
  getLimitTopicNames(setNameState);
  getLimitTopics(setTopicState);
  getLimitPrice(setPriceState);
};
const getLimitDate = (setState) => {
  get(child(dbRef, `limitDate`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        setState(new Date(snapshot.val()).getTime());
      } else {
        console.log("no data available");
      }
    })
    .catch((error) => {
      console.error(error);
    });
};
const getHotNumbers = (setState) => {
  get(child(dbRef, `hotNumbers`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        setState(snapshot.val());
      } else {
        console.log("no data available");
      }
    })
    .catch((error) => {
      console.error(error);
    });
};
const getHotTopics = (setState) => {
  get(child(dbRef, `hotTopics`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        setState(snapshot.val());
      } else {
        console.log("no data available");
      }
    })
    .catch((error) => {
      console.error(error);
    });
};
const getText = (setState, location) => {
  get(child(dbRef, location))
    .then((snapshot) => {
      if (snapshot.exists()) {
        setState(snapshot.val());
      } else {
        console.log("no data available");
      }
    })
    .catch((error) => {
      console.error(error);
    });
};
export { getLimitInfo, getLimitDate, getHotTopics, getHotNumbers, getText };
