import Papa from "papaparse";
import { useEffect, useState, useRef } from "react";
import countries from "../text/countries_codes_and_coordinates.csv";
import Flag from "react-world-flags";
import FlippableCard from "./flipCard/flippable-card";
import visaServiceInfo from "../text/visaServiceInfo.json";
function Visa() {
  const [countryCodeList, setCountryCodeList] = useState([]);
  const initialized = useRef(false);
  const imagesZero = require.context("../image/Visa", true);
  const imageList = imagesZero.keys().map((image) => imagesZero(image));
  console.log(imageList);
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      Papa.parse(countries, {
        download: true,
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          setCountryCodeList(results.data);
        },
      });
    }
    return true
    
  }, []);
  const visaWrapper = {
    width: `1400px`,
    height: `1000px`,
    display: `flex`,
    flexWrap: `wrap`,
    justifyContent: `space-evenly`,
    alignItems: `center`,
    backgroundColor: `white`,
    boxShadow: `2px 2px 2px grey`,
    overflow: `auto`,
    gap: `10px`,
    scrollbarWidth: `thin`,
    marginTop: `10px`,
    marginBottom: `10px`,
  };
  const flagHolderStyle = {
    display: `flex`,
    flexDirection: `column`,
    alignItems: `center`,
    padding: `20px`,
    height: `100px`,
    backgroundColor: `#E1EAF5`,
    flexBasis: `10%`,
  };
  const titleStyle = {
    height: `40px`,
    backgroundColor: `white`,
    width: `1400px`,
    display: `flex`,
    justifyContent: `flex-start`,
    alignItems: `center`,
    boxShadow: `2px 2px 2px grey`,
    marginTop: `10px`,
    marginBottom: `10px`,
  };
  const spanStyle = {
    fontSize: `1.4em`,
    fontWeight: `bold`,
    paddingLeft: `20px`,
    paddingRight: `20px`,
    color: `#4dd7ed`,
  };
  const contractWrapperStyle ={
    width: `1400px`,
    height: `800px`,
    display: `flex`,
    flexDirection:`column`,
    backgroundColor: `white`,
    boxShadow: `2px 2px 2px grey`,
  }
  const flags = countryCodeList.map((flag, index) => (
    <div key={index} style={flagHolderStyle}>
      <Flag
        code={flag["Alpha-3 code"].toLowerCase().replace(/[^a-zA-Z0-9]+/g, "")}
        height="60"
        fallback={<span>Unknown</span>}
      />
      <span>{flag["Country"].replace(/[^a-zA-Z0-9]+/g, " ")}</span>
    </div>
  ));
  const textWrapperStyle = {
    width: `1400px`,
    height: `800px`,
    backgroundColor: `white`,
    boxShadow: `2px 2px 2px grey`,
    marginTop: `10px`,
    marginBottom: `10px`,
    display: `flex`,
    justifyContent: `space-around`,
    alignItems: `center`,
  };
  const flippableCardItems = visaServiceInfo.map((item, index) => (
    <FlippableCard info={item} key={index} image={imageList[index]} />
  ));
  const businessWrapperStyle = {};
  const migrantWrapperStyle = {};
  return (
    <div
      style={{
        display: `flex`,
        justifyContent: `center`,
        backgroundColor: `#DDDDDD`,
        alignItems: `center`,
        flexDirection: `column`,
      }}
    >
      <div style={titleStyle}>
        <span style={spanStyle}>支持办理签证服务的国家及地区</span>
      </div>
      <div style={visaWrapper}>{countryCodeList == [] ? null : flags}</div>
      <div style={titleStyle}>
        <span style={spanStyle}>办理签证服务</span>
      </div>
      <div style={textWrapperStyle}>{flippableCardItems}</div>
      
    </div>
  );
}

export default Visa;
