import React from "react";

const DateTimeDisplay = ({ value, type, isDanger }) => {
  return (
    <div
      className={isDanger ? "countdown danger" : "countdown"}
      style={{
        display: `flex`,
        flexDirection: `row`,
        alignItems: `center`,
        gap: `5px`,
      }}
    >
      <em
        style={{
          fontStyle: `normal`,
          fontWeight: `bold`,
          fontSize: `22px`,
          color: `#fec0cb`,
        }}
      >
        {value}
      </em>
      <span>{type}</span>
    </div>
  );
};

export default DateTimeDisplay;
