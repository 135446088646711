import agency from "../image/buyingOptions/agency.jpg";
import office from "../image/buyingOptions/office.jpg";
import home from "../image/buyingOptions/home.jpg";
import text from "../text/buyingOptions.json";
import { useState } from "react";
function BuyingOptions() {
  const [hoverIndex, setHoverIndex] = useState(null);
  const imageList = [home, office, agency];
  const boWrapperStyle = {
    display: `flex`,
    width: `1400px`,
    height: `100%`,
    gap: `10px`,
  };
  const titleStyle = {
    textAlign: "center",
    color: "#000",
    fontSize: "20px",
    margin: "10px 0",
    fontWeight: "bold",
    textTransform: `uppercase`,
  };
  const buttonStyle = {
    background: `#fec0cb`,
    fontSize: "16px",
    color: "#fff",
    fontWeight: "bold",
    display: "inline-block",
    padding: "0 30px",
    height: "40px",
    lineHeight: "40px",
    borderRadius: "40px",
    width: `100px`,
    cursor: `pointer`,
  };
  const imageListItem = imageList.map((image, index) => (
    <div
      key={index}
      style={{
        flexBasis: `33.33333%`,
        display: `flex`,
        flexDirection: `column`,
        alignItems: `center`,
      }}
    >
      <img
        src={image}
        style={{
          height: `70%`,
          width: `100%`,
          objectFit: `cover`,
          transform: `${hoverIndex === index ? `scale(1.2)` : `scale(1)`}`,
          transition: `transform 0.5s ease`,
        }}
        onMouseEnter={() => setHoverIndex(index)}
        onMouseLeave={() => setHoverIndex(null)}
      ></img>
      <p style={titleStyle}>{text[index].titleOne}</p>
      <span style={buttonStyle}>{text[index].titleTwo}</span>
    </div>
  ));
  return (
    <div
      className="buyingOptions"
      style={{ display: `flex`, justifyContent: `center` }}
    >
      <div className="buyingOptionWrapper" style={boWrapperStyle}>
        {imageListItem}
      </div>
    </div>
  );
}

export default BuyingOptions;
