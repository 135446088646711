import { Link } from "react-router-dom";
import { useLayoutEffect, useState } from "react";
import pDetail from "../text/planDetail.json";
import {planSlider} from "./ImageRequire";
import { getIndexWithId } from "./HelperFunctions";
function PlanDetail() {
  const currentURL = window.location.href.split("/");
  const planCategory = currentURL[currentURL.length - 2];
  const planId = currentURL[currentURL.length - 1];
  const planIndex = getIndexWithId(pDetail[planCategory], planId);
  const sliderList = planSlider[planCategory];
  const sliderImages = sliderList[planIndex];
  const poster = sliderList[sliderList.length-1];
  const imageList = poster.keys().map((image) => poster(image));
  const sliderImageList = sliderImages.keys().map((image) => sliderImages(image));
  const [currentIndex, setCurrentIndex] = useState(0);
  const itemList = ["产品详情"];
  const planWrapperStyle = {
    height: `200vh`,
    width: `100vw`,
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    backgroundColor: `#DDDDDD`,
  };
  const wrapperStyle = {
    height: `100%`,
    width: `1400px`,
    display: `flex`,
    flexDirection: `column`,
    alignItems: `center`,
    gap: `10px`,
    flexWrap: `nowrap`,
  };
  const headerStyle = {
    margin: `10px`,
    height: `70px`,
    boxShadow: `3px 3px 3px grey`,
    backgroundColor: `white`,
    display: `flex`,
    alignItems: `center`,
    width: `100%`,
  };
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  const imageStyle = {
    height: `100%`,
    objectFit: `contain`,
    width: `100%`,
  };
  const imageWrapperStyle = {
    width: `100%`,
    margin: `10px`,
    height: `600px`,
    flexGrow: `1`,
    boxShadow: `3px 3px 3px grey`,
    backgroundColor: `white`,
  };
  const headerItems = itemList.map((item, index) => (
    <div
      key={index}
      style={{
        fontSize: `1.2rem`,
        fontWeight: `600`,
        color: `#4dd7ed`,
        flexShrink: `0`,
        paddingLeft: `20px`,
        paddingRight: `20px`,
      }}
    >
      {item}
    </div>
  ));
  const infoStyle = {
    width: `100%`,
    margin: `10px`,
    height: `600px`,
    boxShadow: `3px 3px 3px grey`,
    backgroundColor: `white`,
    display: `flex`,
    flexDirection: `row`,
    justifyContent: `space-evenly`,
  };
  const imgDisplayStyle = {
    width: `50%`,
    overflowX: `hidden`,
  };
  const textDisplayStyle = {
    height: `200px`,
    display: `flex`,
    flexDirection: `row`,
    width: `100%`,
    flexWrap: `wrap`,
  };
  const pStyle = {
    borderBottom: `1px solid #DDDDDD`,
    margin: `0`,
    paddingTop: `10px`,
    paddingBottom: `10px`,
    flexBasis: `49.65%`,
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
  };
  const rightInfoStyle = {
    flexGrow: `1`,
    display: `flex`,
    flexDirection: `column`,
    width: `50%`,
  };
  const costStyle = {
    display: `flex`,
    height: `50px`,
    width: `100%`,
    backgroundColor: `#f5f5f5`,
    alignItems: `center`,
  };
  const spanStyle = {
    color: `red`,
    fontSize: `1.2em`,
    padding: `5px`,
  };
  const midSpanStyle = {
    color: `red`,
    fontSize: `1.7em`,
    fontWeight: `bold`,
    padding: `5px`,
  };
  const titleStyle = {
    display: `flex`,
    height: `70px`,
    width: `100%`,
    alignItems: `center`,
  };
  const titleSpanStyle = {
    fontSize: `1.7em`,
    fontWeight: `bold`,
  };
  const featureStyle = {
    display: `flex`,
    flexDirection: `column`,
    backgroundColor: `#f5f5f5`,
    flexGrow: `1`,
    alignItems: `flex-start`,
    marginTop: `3px`,
    marginBottom: `3px`,
    border: `1px dashed #DDDDDD`,
    padding: `10px`,
  };
  const featureSpanStyle = {
    fontSize: `1.4em`,
    fontWeight: `bold`,
    padding: `5px`,
  };
  const featureItemStyle = {
    padding: `5px`,
  };
  

  const hotItem = pDetail[planCategory][planIndex];
  console.log(hotItem);
  console.log(window.location.href.split("/"));
  const featureItems = hotItem === undefined ? null : hotItem["features"].map((item, index) => (
    <span key={index} style={featureItemStyle}>
      &#9733;&#9733;{item}
    </span>
  ));
  const sectionList = sliderImageList.map((url, index) => (
    <section
      key={index}
      style={{
        backgroundImage: `url(${url})`,
        transform: `translateX(-${
          (currentIndex % sliderImageList.length) * 100
        }%)`,
        transition: `transform 0.5s ease-out`,
        backgroundSize: `cover`,
        flex: `1`,
        width: `100%`,
        display: `flex`,
        justifyContent: `center`,
        alignItems: `center`,
      }}
    ></section>
  ));
  const selectionList = sliderImageList.map((url, index) => (
    <div
      key={index}
      style={{
        padding: `10px`,
        backgroundColor: `${index === currentIndex ? `#4dd7ed` : `white`}`,
      }}
      onMouseEnter={() => setCurrentIndex(index)}
    >
      <section
        key={index}
        style={{
          backgroundImage: `url(${url})`,
          backgroundSize: `cover`,
          height: `100%`,
          width: `150px`,
          display: `flex`,
          justifyContent: `center`,
          alignItems: `center`,
        }}
      ></section>
    </div>
  ));
  const sectionWrapperStyle = {
    width: `300%`,
    height: `400px`,
    display: `flex`,
    overflow: `hidden`,
  };
  const sliderSelectionStyle = {
    height: `120px`,
    width: `100%`,
    display: `flex`,
  };
  return (
    <div>
      <div className="planWrapper" style={planWrapperStyle}>
        {hotItem === undefined ? null : (
          <div className="wrapper" style={wrapperStyle}>
            <div style={headerStyle}>{headerItems}</div>
            <div style={infoStyle}>
              <div style={imgDisplayStyle}>
                <div style={sectionWrapperStyle}>{sectionList}</div>
                <div style={sliderSelectionStyle}>{selectionList}</div>
              </div>
              <div style={rightInfoStyle}>
                <div style={titleStyle}>
                  <span style={titleSpanStyle}>{hotItem["title"]}</span>
                </div>
                <div style={costStyle}>
                  <div style={{ marginLeft: `10px` }}>
                    <span style={spanStyle}>C$</span>
                    <span style={midSpanStyle}>{hotItem["cost"]}</span>
                    <span style={spanStyle}>CAD/起</span>
                  </div>
                </div>
                <div style={textDisplayStyle}>
                  <p style={pStyle}>产品编号: {hotItem["code"]}</p>
                  <p style={pStyle}>产品代码: {hotItem["id"]}</p>
                  <p style={pStyle}>出发城市: {hotItem["startCity"]}</p>
                  <p style={pStyle}>结束城市: {hotItem["endCity"]}</p>
                  <p style={pStyle}>产品天数: {hotItem["duration"]}</p>
                  <p style={pStyle}>参团方式: {hotItem["join"]}</p>
                  <p style={pStyle}>普通类型: {hotItem["type"]}</p>
                  <p style={pStyle}>
                    产品特色: {hotItem["tags"][0]}, {hotItem["tags"][1]}
                  </p>
                </div>
                <div style={featureStyle}>
                  <span style={featureSpanStyle}>特色及说明</span>
                  {featureItems}
                </div>
              </div>
            </div>
            <div style={imageWrapperStyle}>
              <img src={imageList[planIndex]} style={imageStyle}></img>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default PlanDetail;
