const hotPoster = require.context("../image/poster/hot_content", true);
const hot0 = require.context("../image/planSlider/hot/0", true);
const hot1 = require.context("../image/planSlider/hot/1", true);
const hot2 = require.context("../image/planSlider/hot/2", true);
const hot3 = require.context("../image/planSlider/hot/3", true);
const hot4 = require.context("../image/planSlider/hot/4", true);
const certificate0 = require.context(
  "../image/contact/certificate/certificate0",
  true
);
const certificate1 = require.context(
  "../image/contact/certificate/certificate1",
  true
);
const certificate2 = require.context(
  "../image/contact/certificate/certificate2",
  true
);
const certificate3 = require.context(
  "../image/contact/certificate/certificate3",
  true
);
const certificate4 = require.context(
  "../image/contact/certificate/certificate4",
  true
);
const certificate5 = require.context(
  "../image/contact/certificate/certificate5",
  true
);
const certificate6 = require.context(
  "../image/contact/certificate/certificate6",
  true
);
const certificate7 = require.context(
  "../image/contact/certificate/certificate7",
  true
);
const certificate8 = require.context(
  "../image/contact/certificate/certificate8",
  true
);

const limit0 = require.context("../image/planSlider/limit/0", true);
const limit1 = require.context("../image/planSlider/limit/1", true);
const limit2 = require.context("../image/planSlider/limit/2", true);
const limit3 = require.context("../image/planSlider/limit/3", true);
const limit4 = require.context("../image/planSlider/limit/4", true);
const limit5 = require.context("../image/planSlider/limit/5", true);
const limit6 = require.context("../image/planSlider/limit/6", true);
const limit7 = require.context("../image/planSlider/limit/7", true);
const limit8 = require.context("../image/planSlider/limit/8", true);
const limit9 = require.context("../image/planSlider/limit/9", true);
const limit10 = require.context("../image/planSlider/limit/10", true);
const limit11 = require.context("../image/planSlider/limit/11", true);
const limit12 = require.context("../image/planSlider/limit/12", true);
const limit13 = require.context("../image/planSlider/limit/13", true);
const limit14 = require.context("../image/planSlider/limit/14", true);
const limit15 = require.context("../image/planSlider/limit/15", true);
const limit16 = require.context("../image/planSlider/limit/16", true);
const limit17 = require.context("../image/planSlider/limit/17", true);
const limit18 = require.context("../image/planSlider/limit/18", true);
const limit19 = require.context("../image/planSlider/limit/19", true);
const limit20 = require.context("../image/planSlider/limit/20", true);
const limit21 = require.context("../image/planSlider/limit/21", true);
const limit22 = require.context("../image/planSlider/limit/22", true);
const limit23 = require.context("../image/planSlider/limit/23", true);
const limit24 = require.context("../image/planSlider/limit/24", true);
const limit25 = require.context("../image/planSlider/limit/25", true);
const limit26 = require.context("../image/planSlider/limit/26", true);
const limit27 = require.context("../image/planSlider/limit/27", true);
const limit28 = require.context("../image/planSlider/limit/28", true);
const limit29 = require.context("../image/planSlider/limit/29", true);
const limit30 = require.context("../image/planSlider/limit/30", true);
const limit31 = require.context("../image/planSlider/limit/31", true);
const limitPoster = require.context("../image/poster/limit_content", true);

const slider0 = require.context("../image/planSlider/slider/0", true);
const slider1 = require.context("../image/planSlider/slider/1", true);
const slider2 = require.context("../image/planSlider/slider/2", true);
const slider3 = require.context("../image/planSlider/slider/3", true);
const sliderPoster = require.context("../image/poster/slider_content", true);

const bundle0 = require.context("../image/planSlider/bundle/0", true);
const bundle1 = require.context("../image/planSlider/bundle/1", true);
const bundle2 = require.context("../image/planSlider/bundle/2", true);
const bundle3 = require.context("../image/planSlider/bundle/3", true);
const bundlePoster = require.context("../image/poster/bundle_content", true);

const theme0 = require.context("../image/planSlider/theme/0", true);
const theme1 = require.context("../image/planSlider/theme/1", true);
const theme2 = require.context("../image/planSlider/theme/2", true);
const theme3 = require.context("../image/planSlider/theme/3", true);
const theme4 = require.context("../image/planSlider/theme/4", true);
const themePoster = require.context("../image/poster/theme_content", true);

const limitSlider = [
  limit0,
  limit1,
  limit2,
  limit3,
  limit4,
  limit5,
  limit6,
  limit7,
  limit8,
  limit9,
  limit10,
  limit11,
  limit12,
  limit13,
  limit14,
  limit15,
  limit16,
  limit17,
  limit18,
  limit19,
  limit20,
  limit21,
  limit22,
  limit23,
  limit24,
  limit25,
  limit26,
  limit27,
  limit28,
  limit29,
  limit30,
  limit31,
  limitPoster,
];
const planSlider = {
  hot: [hot0, hot1, hot2, hot3, hot4, hotPoster],
  limit: limitSlider,
  slider: [slider0, slider1, slider2, slider3, sliderPoster],
  bundle: [bundle0, bundle1, bundle2, bundle3, bundlePoster],
  theme: [theme0, theme1, theme2, theme3, theme4, themePoster],
};
const certificateImage = [
  certificate0,
  certificate1,
  certificate2,
  certificate3,
  certificate4,
  certificate5,
  certificate6,
  certificate7,
  certificate8,
];

export { planSlider, certificateImage };
