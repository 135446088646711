import * as AiIcons from "react-icons/ai";
import { useState } from "react";
function HotContent({ image, handleClick }) {
  const [isHover, setIsHover] = useState(false);
  const contentHolderStyle = {
    position: `fixed`,
    height: `100vh`,
    width: `100vw`,
    backgroundColor: `rgba(0,0,0,0.5)`,
    left: `0`,
    top: `0`,
    zIndex: `99999`,
  };
  const imageStyle = {
    height: `100%`,
    width: `auto`,
  };
  const closeStyle = {
    color: `white`,
    position: `absolute`,
    right: `50px`,
    top: `20px`,
    size: `4em`,
    padding: `20px`,
    backgroundColor: isHover ? `rgba(0,0,0,0.7)` : `rgba(0,0,0,0.5)`,
  };
  return (
    <div>
      <div style={contentHolderStyle}>
        <div
          style={closeStyle}
          onMouseEnter={() => {
            setIsHover(true);
          }}
          onMouseLeave={() => {
            setIsHover(false);
          }}
          onClick={handleClick}
        >
          <AiIcons.AiOutlineClose size={"4em"} />
        </div>
        <img src={image} style={imageStyle}></img>
      </div>
    </div>
  );
}

export default HotContent;
