import { User, Globe, ChevronDown, Grid, Search } from "react-feather";
import { useState } from "react";
import logo from "../image/logo/logo.jpg";
import { useViewport } from "../hooks/useViewport";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { Link } from "react-router-dom";
function Header(props) {
  const [showLanDropdown, setShowLanDropdown] = useState(false);
  const [language, setLanguage] = useState("简体中文");
  const [hoverIndex, setHoverIndex] = useState(null);
  const [tabHoverIndex, setTabHoverIndex] = useState(null);
  const [sideBar, setSideBar] = useState(true);
  const { width } = useViewport();
  const breakpoint = 620;
  const headerStyle = {
    display: `flex`,
    flexDirection: `column`,
    flexWrap: `nowrap`,
    alignItems: `stretch`,
  };
  const PINK = "#fec0cb";
  const BLUE = "#4dd7ed";
  const topStyle = {
    minHeight: `40px`,
    maxHeight: `40px`,
    width: `100%`,
    //flexGrow: `1.8`,
    display: `flex`,
    flexDirection: `row`,
    borderBottom: `1px solid #4dd7ed`,
    justifyContent: `center`,
  };
  const middleStyle = {
    flexGrow: `3.9`,
    display: `flex`,
    flexDirection: `row`,
    justifyContent: `center`,
    minHeight: `62px`,
  };
  const topContainerStyle = {
    width: `50vw`,
    display: `flex`,
    flexDirection: `row`,
    justifyContent: `center`,
    alignItems: `center`,
    gap: `20px`,
  };
  const loginStye = {
    display: `flex`,
    flexDirection: `row`,
    alignItems: `center`,
    justifyContent: `center`,
    gap: `5px`,
  };
  const languageStyle = {
    display: `flex`,
    flexDirection: `row`,
    justifyContent: `center`,
    alignItems: `center`,
    //height: `100%`,
    gap: `5px`,
    caretColor: `transparent`,
  };
  const languageContainerStyle = {
    position: `relative`,
    display: `flex`,
    flexDirection: `column`,
    justifyContent: `center`,
    top: `${showLanDropdown ? `63px` : `0px`}`,
    minWidth: `100px`,
    //height: `100%`,
  };
  const middleWrapperStyle = {
    maxHeight: `100%`,
    minWidth: `1000px`,
    width: `1400px`,
    display: `flex`,
    alignItems: `center`,
    gap: `30px`,
    caretColor: `transparent`,
  };
  const bottomWrapperStyle = {
    maxHeight: `100%`,
    minWidth: `1000px`,
    width: `1400px`,
    display: `flex`,
    alignItems: `center`,
    gap: `20px`,
    caretColor: `transparent`,
  };
  const tabStyle = {
    display: `flex`,
    gap: `30px`,
  };
  const SetLanguage = (name) => {
    setLanguage(name);
    setShowLanDropdown(false);
    setHoverIndex(null);
  };
  const clickBurger = () => {
    setSideBar(!sideBar);
  };
  const bottomStyle = {
    //flexGrow: `1.1`,
    backgroundColor: `#4dd7ed`,
    display: `flex`,
    justifyContent: `center`,
    minHeight: `20px`,
    maxHeight: `50px`,
  };
  const tabItemsList = ["快速导航", "选项1", "选项2", "选项3", "选项4"];
  const bottomItemList = [
    "机票",
    "签证",
    "拼团游",
    "商旅",
    "亲子",
    "宠物",
    "户外",
    "出行好物",
    "旅游博主",
    "会员积分",
    "关于我们",
  ];
  const languages = [
    { name: "简体中文", code: "cns" },
    { name: "繁體中文", code: "cnt" },
    { name: "English", code: "en" },
    { name: "日本語", code: "jp" },
  ];
  const languagesList = languages.map((item, index) => (
    <li
      key={item.code}
      style={{
        backgroundColor: `${hoverIndex === index ? `#fec0cb` : `#FFFFFF`}`,
        margin: `0`,
        padding: `5px 0 5px 0`,
        maxWidth: `100%`,
        width: `100%`,
        cursor: `pointer`,
      }}
      onMouseLeave={() => setHoverIndex(null)}
      onMouseEnter={() => setHoverIndex(index)}
      onClick={() => SetLanguage(item.name)}
    >
      {item.name}
    </li>
  ));
  const tabItems = props.tab.map((item, index) => (
    <div
      key={index}
      style={{
        fontWeight: `600`,
        color: `${tabHoverIndex === index ? `#4dd7ed` : `#000000`}`,
        cursor: `pointer`,
      }}
      onMouseLeave={() => setTabHoverIndex(null)}
      onMouseEnter={() => setTabHoverIndex(index)}
    >
      {item}
    </div>
  ));
  const bottomItems = bottomItemList.map((item, index) => (
    <Link
      to={item === "签证" ? "/visa" : "/"}
      target="_blank"
      key={index}
      style={{
        fontWeight: `600`,
        color: `#ffffff`,
        cursor: `pointer`,
        flexShrink: `0`,
      }}
    >
      {item}
    </Link>
  ));
  const mobileHeaderStyle = {
    height: `50px`,
    width: `100vw`,
    display: `flex`,
    justifyContent: `space-between`,
    alignItems: `center`,
  };
  const menuStyle = {
    marginLeft: `1rem`,
    fontSize: `2rem`,
    background: `none`,
  };
  const sideBarStyle = {
    position: `fixed`,
    left: sideBar ? `-100%` : `0`,
    zIndex: `99`,
    background: `white`,
    top: `0`,
    width: `100vw`,
    height: `100vh`,
    transition: `500ms`,
  };
  const sideBarItem = bottomItemList.map((item, index) => (
    <li
      key={index}
      onClick={clickBurger}
      style={{
        padding: `5px 75px 5px`,
        fontSize: `2rem`,
        caretColor: `transparent`,
        backgroundColor: tabHoverIndex === index ? `gray` : `white`,
      }}
      onMouseLeave={() => setTabHoverIndex(null)}
      onMouseEnter={() => setTabHoverIndex(index)}
    >
      {item}
    </li>
  ));
  const sideMenuUl = {
    listStyleType: `none`,
    display: `flex`,
    justifyContent: `center`,
    flexDirection: `column`,
    alignItems: `center`,
    margin: `0`,
    padding: `0`,
  };
  const navbarToggle = {
    marginLeft: `1rem`,
    fontSize: `2rem`,
    background: `none`,
    margin: `25px 0px 10px`,
    padding: `0`,
  };
  //TODO: implement link
  const mobileHeader = (
    <div style={{ position: `relative` }}>
      <div style={mobileHeaderStyle}>
        <div className="menuBars" style={menuStyle}>
          <FaIcons.FaBars onClick={clickBurger} />
        </div>
        <div className="logo" style={{}}>
          <img
            style={{ objectFit: `cover`, width: `100px`, height: `50px` }}
            src={logo}
          ></img>
        </div>
      </div>
      <nav style={sideBarStyle}>
        <ul style={sideMenuUl}>
          <li style={navbarToggle}>
            <AiIcons.AiOutlineClose onClick={clickBurger} />
          </li>
          {sideBarItem}
        </ul>
      </nav>
    </div>
  );
  sideBar
    ? (document.body.style.overflow = "auto")
    : (document.body.style.overflow = "hidden");
  return width < breakpoint ? (
    mobileHeader
  ) : (
    <div className="header" style={headerStyle}>
      <div className="top" style={topStyle}>
        <div className="topContainer" style={topContainerStyle}>
          <div
            style={{
              width: `1400px`,
              display: `flex`,
              justifyContent: `flex-end`,
              alignItems: `center`,
            }}
          >
            <div className="login" style={loginStye}>
              <User size={15} />
              <a>
                <span>登录</span>
              </a>
              <p> | </p>
              <a>
                <span>注册</span>
              </a>
            </div>
            <div className="languageContainer" style={languageContainerStyle}>
              <div
                className="language"
                style={languageStyle}
                onClick={() => {
                  setShowLanDropdown(!showLanDropdown);
                }}
              >
                <Globe size={15} />
                {language}
                <ChevronDown size={15} />
              </div>
              <div className="lanDropDown">
                {showLanDropdown ? <ul>{languagesList}</ul> : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="middle" style={middleStyle}>
        <div className="middlerWrapper" style={middleWrapperStyle}>
          <div className="logo" style={{}}>
            <img
              style={{ objectFit: `cover`, width: `170px`, height: `50px` }}
              src={logo}
            ></img>
          </div>
          <div className="tabs" style={tabStyle}>
            {props.tab.length <= 0 ? null : tabItems}
          </div>
          <div
            className="searchBarWrapper"
            style={{
              display: `flex`,
              justifyContent: `center`,
              alignItems: `center`,
              flex: `2`,
            }}
          >
            <div
              className="searchBar"
              style={{
                display: `flex`,
                border: `solid 1px #fec0cb`,
                borderRadius: `7% / 50%`,
                paddingLeft: `7px`,
              }}
            >
              <input
                type="text"
                style={{
                  border: `none`,
                  fontSize: `15px`,
                  margin: `2px`,
                  caretColor: `black`,
                }}
              ></input>
              <div
                className="searchButton"
                style={{
                  width: `30px`,
                  height: `30px`,
                  backgroundColor: `#4dd7ed`,
                  borderRadius: `50%`,
                  display: `flex`,
                  justifyContent: `center`,
                  alignItems: `center`,
                  cursor: `pointer`,
                }}
              >
                <Search size={15} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom" style={bottomStyle}>
        <div className="bottomWrapper" style={bottomWrapperStyle}>
          <div
            className="menu"
            style={{
              padding: `0 50px 0 50px`,
              backgroundColor: `#fec0cb`,
              display: `flex`,
              height: `100%`,
              textAlign: `center`,
              alignItems: `center`,
              gap: `5px`,
            }}
          >
            <Grid size={30} />
            <p style={{ flexShrink: `0` }}>目的地参团</p>
          </div>
          <div className="bottomItem" style={{ display: `flex`, gap: `70px` }}>
            {bottomItems}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
