import { useState } from "react";
import Rating from "@mui/material/Rating";
import HotContent from "./HotContent";
import useScrollBlock from "../hooks/useScrollBlock";
import { Link } from "react-router-dom";
import pDetail from "../text/planDetail.json";

function HotTopics({ images, info, price, ratings, purchase }) {
  //images is a list of image source
  //info is a list of list of string
  const [blockScroll, allowScroll] = useScrollBlock();
  const imagesZero = require.context("../image/poster/hot_content", true);
  const imageList = imagesZero.keys().map((image) => imagesZero(image));
  const [topicHoverIndex, setTopicHoverIndex] = useState(null);
  const [showDetail, setShowDetail] = useState(false);
  const [detailIndex, setDetailIndex] = useState(null);
  const closeDetail = () => {
    setShowDetail(false);
    allowScroll();
    console.log("hi");
  };
  const setDetail = () => {
    setShowDetail(true);
    blockScroll();
  };
  const cardItems = images.map((image, index) => (
    <li key={index} style={{ flexBasis: `calc(100% / ${images.length})` }}>
      <div
        style={{
          position: `relative`,
          display: `flex`,
          flexDirection: `column`,
          minWidth: `100%`,
          maxWidth: `100%`,
          alignItems: `center`,
        }}
      >
        <img
          src={image}
          style={{ height: `200px`, width: `100%`, objectFit: `cover` }}
        ></img>
        <Rating
          name="read-only"
          value={ratings ? ratings[index] : 0}
          readOnly
        />
        <div
          className="hotTopicPurchase"
          style={{ display: `flex`, alignItems: `flex-end` }}
        >
          <p
            style={{
              fontStyle: `normal`,
              fontWeight: `bold`,
              fontSize: `22px`,
              color: `#4dd7ed`,
              margin: `0`,
            }}
          >
            {purchase ? purchase[index] : 0}
          </p>
          <p
            style={{
              fontStyle: `normal`,
              fontSize: `16px`,
              margin: `0`,
            }}
          >
            个人已购买
          </p>
        </div>
      </div>
      <div
        style={{
          backgroundColor: `#ffffff`,
          width: `100%`,
          maxWidth: `100%`,
          fontSize: `1px`,
          paddingBottom: `10px`,
        }}
      >
        <div
          style={{
            display: `flex`,
            flexDirection: `column`,
            alignItems: `center`,
            cursor: `pointer`,
          }}
          onMouseEnter={() => setTopicHoverIndex(index)}
          onMouseLeave={() => setTopicHoverIndex(null)}
        >
          <Link
            to={`/plan_detail/hot/${pDetail.hot[index].id}`}
            target="_blank"
            style={{
              fontSize: `13px`,
              color: `${topicHoverIndex === index ? `#4dd7ed` : `#000000`}`,
              minHeight: `70px`,
              maxHeight: `70px`,
            }}
            onClick={() => {
              //setDetail();
              //setDetailIndex(index);
            }}
          >
            {info.length === 0 ? info[0] : info[index]}
          </Link>
          <div
            className="hotTopicPrice"
            style={{ display: `flex`, alignItems: `flex-end` }}
          >
            <p
              style={{
                fontStyle: `normal`,
                fontWeight: `bold`,
                fontSize: `22px`,
                color: `#4dd7ed`,
                margin: `0`,
              }}
            >
              ${price ? price[index] : 0}
            </p>
            <p
              style={{
                fontStyle: `normal`,
                fontSize: `16px`,
                margin: `0`,
              }}
            >
              起
            </p>
          </div>
        </div>
      </div>
    </li>
  ));
  return (
    <div
      style={{
        height: `100%`,
        width: `1400px`,
        margin: `0`,
        padding: `0`,
        display: `flex`,
        justifyContent: `center`,
        alignItems: `center`,
        position: `relative`,
      }}
    >
      <ul
        style={{
          listStyleType: `none`,
          display: `flex`,
          flexDirection: `row`,
          justifyContent: `space-between`,
          alignItems: `center`,
          maxHeight: `100%`,
          minHeight: `100%`,
          margin: `0`,
          padding: `0`,
          width: `100%`,
          gap: `12px`,
        }}
      >
        {cardItems}
      </ul>
      {showDetail ? (
        <HotContent image={imageList[detailIndex]} handleClick={closeDetail} />
      ) : null}
    </div>
  );
}

export default HotTopics;
