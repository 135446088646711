import "./card.css";
import "./flip-transition.css";
function Card({ onClick, info, image, nodeRef }) {
  const frontStyle = {
    backgroundImage: `url(${image})`,
    backgroundSize: `30%`,
    backgroundRepeat: `no-repeat`,
  };
  const titleStyle = {
    fontSize: `1.2rem`,
    fontWeight: `bold`,
    textDecorationLine: `underline`,
    textDecorationColor: `#4dd7ed`
  };
  const cardItemStyle = {
    textAlign:`left`,
  }
  const contactStyle = {
    fontSize:`1rem`,
    textAlign:`left`,
    display:`flex`,
    flexDirection:`column`,
    justifyContent:`space-around`,
    height:`50%`
  }
  return (
    <div className="card" onClick={onClick} ref={nodeRef}>
      <div className="card-front" style={frontStyle}>
        <div style={{height:`50%`, display:`flex`, flexDirection:`column`,justifyContent:`flex-end`, alignContent:`center`}}>{info.front}</div>
        <div style ={contactStyle}>
          <span style ={{fontSize:`1.5rem`}}>
            联系方式
          </span>
          <span style ={{fontSize:`1rem`}}>
            电子邮箱：{info.contact.email}
          </span>
          <span style ={{fontSize:`1rem`}}>
            客服热线：{info.contact.phone}
          </span>
          <span style ={{fontSize:`1rem`}}>
            微信号：{info.contact.wechat}
          </span>
          <span style ={{fontSize:`1rem`, textAlign:`right`}}>
            点击查看更多信息
          </span>
        </div>
      </div>
      <div className="card-back">

        <span style={titleStyle}>{info.headline.title}</span>
        <span >{info.headline.info}</span>
        <div style={cardItemStyle}>
          <span  style={titleStyle} >{info.consult.title }</span>
          <span s>{": " + info.consult.info}</span>
        </div>
        <div style={cardItemStyle}>
          <span style={titleStyle} >{info.document.title}</span>
          <span >{": " + info.document.info}</span>
        </div>
        <div style={cardItemStyle}>
          <span style={titleStyle}>{info.apply.title }</span>
          <span >{": " + info.apply.info}</span>
        </div>
        <div style={cardItemStyle}>
          <span style={titleStyle} >{info.getVisa.title }</span>
          <span >{": " + info.getVisa.info}</span>
        </div>
        <div style={cardItemStyle}>
          <span style={titleStyle} >{info.getVisa.title }</span>
          <span >{": " + info.getVisa.info}</span>
        </div>
      </div>
    </div>
  );
}
export default Card;
