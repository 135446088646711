import { useState } from "react";
import customOption from "../text/customizeOptions.json";
import customIconText from "../text/customizeIconText.json";
import { ArrowRight, ChevronRight } from "react-feather";
import {
  faSuitcaseRolling,
  faThumbsUp,
  faShop,
  faCar,
  faMapLocation,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function Customize() {
  const [currentIndex, setCurrentIndex] = useState(null);
  const imageZero = require.context("../image/customize", true);
  const imageList = imageZero.keys().map((image) => imageZero(image));
  const iconList = [
    <FontAwesomeIcon
      icon={faSuitcaseRolling}
      style={{ color: "#a8a8a8" }}
      size="2x"
    />,
    <FontAwesomeIcon icon={faShop} style={{ color: "#a8a8a8" }} size="2x" />,
    <FontAwesomeIcon icon={faCar} style={{ color: "#a8a8a8" }} size="2x" />,
    <FontAwesomeIcon
      icon={faMapLocation}
      style={{ color: "#a8a8a8" }}
      size="2x"
    />,
    <FontAwesomeIcon
      icon={faThumbsUp}
      style={{ color: "#a8a8a8" }}
      size="2x"
    />,
    <FontAwesomeIcon icon={faPhone} style={{ color: "#a8a8a8" }} size="2x" />,
  ];
  const iconTextOneStyle = {
    margin: `0`,
    padding: `0`,
    fontSize: `18px`,
    color: `#fec0cb`,
  };
  const customizedIconItems = iconList.map((icon, index) => (
    <div
      key={index}
      style={{
        flexBasis: `33.3333%`,
        display: `flex`,
        flexDirection: `column`,
        alignItems: `center`,
      }}
    >
      <div
        className="iconWrapper"
        style={{
          display: `flex`,
          justifyContent: `center`,
          border: `2px solid #a8a8a8`,
          width: `50px`,
          height: `50px`,
          alignItems: `center`,
          borderRadius: `50%`,
        }}
      >
        {icon}
      </div>
      <p style={iconTextOneStyle}>{customIconText[index].titleOne}</p>
      <p style={{ margin: `0`, padding: `0` }}>
        {customIconText[index].titleTwo}
      </p>
    </div>
  ));
  const imageListItems = imageList.map((image, index) => (
    <div
      key={index}
      className="bundleItem"
      style={{
        backgroundImage: `url(${image})`,
        height: `100%`,
        flex: `1 1 0`,
        backgroundSize: `cover`,
        backgroundPosition: `bottom 40% right 50%`,
        transform: `${currentIndex === index ? `scale(1.2)` : `scale(1)`}`,
        transition: `transform 0.5s ease`,
        display: `flex`,
        flexDirection: `column`,
        alignItems: `flex-start`,
        color: `#fff`,
      }}
      onMouseEnter={() => setCurrentIndex(index)}
      onMouseOut={() => setCurrentIndex(null)}
    >
      <b style={{ fontSize: `24px`, paddingTop: `10px`, paddingLeft: `10px` }}>
        {customOption[index].titleOne}
      </b>
      <b style={{ fontSize: `14px`, paddingLeft: `10px` }}>
        {customOption[index].titleTwo}
      </b>
    </div>
  ));
  const customWrapperStyle = {
    height: `100%`,
    width: `1400px`,
    display: `flex`,
    flexDirection: `row`,
    justifyContent: `center`,
    alignItems: `center`,
    gap: `12px`,
  };
  const cusLeftSectionStyle = {
    height: `100%`,
    width: `auto`,
    width: `200px`,
    flex: `2.5 1 0`,
    display: `flex`,
    flexDirection: `column`,
  };
  const cusRightSectionStyle = {
    height: `100%`,
    flex: `1 1 0`,
    backgroundColor: `rgb(77, 215, 237)`,
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
  };
  const cusLeftTopStyle = {
    display: `flex`,
    flex: `2.5 1 0`,
    width: `100%`,
    border: `1px solid #eee`,
  };
  const cusLeftBottomStyle = {
    display: `flex`,
    flex: `1 1 0`,
    width: `100%`,
  };
  const customizeStyle = {
    display: `flex`,
    justifyContent: `center`,
  };
  const customHeaderLineStyle = {
    width: `100%`,
    textAlign: `center`,
    borderBottom: `1px solid #fff`,
    lineHeight: `0.1em`,
    margin: `10px 0 20px`,
  };
  const headerTwoSpanStyle = {
    background: `rgb(77, 215, 237)`,
    padding: `0 10px`,
  };
  const customIconsStyle = {
    flex: `2 1 0`,
    display: `flex`,
    flexWrap: `wrap`,
    alignItems: `center`,
  };
  const customHeadLineStyle = {
    flex: `1 1 0`,
    display: `flex`,
    flexDirection: `column`,
    alignItems: `flex-start`,
    paddingLeft: `40px`,
    paddingTop: `40px`,
  };
  const customHeadLineTextStyle = {
    margin: `0`,
    padding: `0`,
    color: `rgba(100,100,100,0.5)`,
    fontSize: `47px`,
    lineHeight: `0.9`,
  };
  return (
    <div className="customize" style={customizeStyle}>
      <div className="customWrapper" style={customWrapperStyle}>
        <div className="cus-leftSection" style={cusLeftSectionStyle}>
          <div className="cus-leftTopSection" style={cusLeftTopStyle}>
            <div className="customHeadLine" style={customHeadLineStyle}>
              <h4
                style={{
                  color: `#424242`,
                  fontSize: `44px`,
                  fontWeight: `normal`,
                  margin: `0`,
                  padding: `0`,
                }}
              >
                私人定制小包团
              </h4>
              <p style={customHeadLineTextStyle}>CUSTOMIZED</p>
              <p style={customHeadLineTextStyle}>TOUR</p>
              <p
                style={{
                  fontSize: `15px`,
                  letterSpacing: `14px`,
                  color: `#fec0cb`,
                }}
              >
                您可以感受到温馨诚意
              </p>
              <a
                target="_blank"
                style={{
                  padding: `10px 20px 10px 20px`,
                  backgroundColor: `#fec0cb`,
                  color: `#fff`,
                  display: `flex`,
                  borderRadius: `15%/50%`,
                  cursor: `pointer`,
                }}
              >
                更多小包团
                <ChevronRight />
              </a>
            </div>
            <div className="customIcons" style={customIconsStyle}>
              {customizedIconItems}
            </div>
          </div>
          <div className="cus-leftBottomSection" style={cusLeftBottomStyle}>
            {imageListItems}
          </div>
        </div>
        <div className="cus-rightSection" style={cusRightSectionStyle}>
          <a
            style={{
              height: `80%`,
              width: `80%`,
              display: `flex`,
              flexDirection: `column`,
              color: `#fff`,
              alignItems: `center`,
              gap: `10px`,
            }}
          >
            <h5
              style={{
                textAlign: `center`,
                fontSize: `36px`,
                margin: `0`,
                padding: `0`,
              }}
            >
              个性定制
            </h5>
            <div className="customHeaderTwo" style={customHeaderLineStyle}>
              <span style={headerTwoSpanStyle}>CUSTOMIZED TOUR</span>
            </div>

            <p
              style={{
                height: `180px`,
                width: `160px`,
                backgroundColor: `#fff`,
                borderRadius: `15px`,
                color: `#000`,
              }}
            >
              二维码PlaceHolder
            </p>
            <div
              className="cusBtn"
              style={{
                backgroundColor: `#FC552D`,
                padding: `10px 20px 10px 20px`,
                borderRadius: `15px`,
                display: `flex`,
                justifyContent: `center`,
                alignItems: `center`,
                gap: `10px`,
                cursor: `pointer`,
              }}
            >
              <span
                style={{
                  fontSize: `25px`,
                  display: `flex`,
                  justifyContent: `center`,
                  alignItems: `center`,
                }}
              >
                开始定制
              </span>
              <div
                style={{
                  borderRadius: `50%`,
                  padding: `2px`,
                  display: `flex`,
                  justifyContent: `center`,
                  backgroundColor: `#FFCFC4`,
                  color: `#FC552D`,
                }}
              >
                <ChevronRight size={15} />
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Customize;
