function AboutUs() {
  const aboutUsWrapperStyle = {
    width: `1400px`,
    height: `90%`,
    display: `flex`,
    flexDirection: `row`,
    color: `#fff`,
  };
  const aboutUsLeftStyle = {
    display: `flex`,
    flex: `1 1 0`,
    backgroundColor: `#70174f`,
    flexDirection: `column`,
  };
  const aboutUsRightStyle = {
    display: `flex`,
    flex: `3 1 0`,

    flexDirection: `column`,
  };
  const aboutUsRightTop = {
    display: `flex`,
    flex: `1 1 0`,
  };
  const aboutUsRightBottom = {
    display: `flex`,
    flex: `1 1 0`,
  };
  const titleStyleOne = {
    fontSize: "36px",
    display: "block",
    lineHeight: "1.2",
    fontWeight: "bold",
  };
  const titleStyleTwo = {
    fontSize: "45px",
    display: "block",
    lineHeight: "1.2",
    fontWeight: "bold",
  };
  const titleStyleThree = { fontSize: `16px`, lineHeight: `1.5` };
  return (
    <div
      className="aboutUs"
      style={{
        display: `flex`,
        justifyContent: `center`,
        alignItems: `center`,
      }}
    >
      <div className="aboutUsWrapper" style={aboutUsWrapperStyle}>
        <div className="aboutUsLeft" style={aboutUsLeftStyle}>
          <a>
            <p
              style={{
                display: `flex`,
                flexDirection: `column`,
                paddingTop: `20px`,
              }}
            >
              <span style={titleStyleThree}>为您提供走心的旅行服务</span>
              <span style={titleStyleOne}>打造不凡的</span>
              <span style={titleStyleTwo}>旅行服务</span>
            </p>
          </a>
        </div>
        <div className="aboutUsRight" style={aboutUsRightStyle}>
          <div className="au-rightTop" style={aboutUsRightTop}>
            <div
              className="au-rt-left"
              style={{
                display: `flex`,
                flex: `1 1 0`,
                backgroundColor: `#347885`,
                flexDirection: `column`,
                alignItems: `center`,
              }}
            >
              <a>
                <p
                  style={{
                    display: `flex`,
                    flexDirection: `column`,
                    paddingTop: `20px`,
                    alignItems: `flex-start`,
                  }}
                >
                  <span style={titleStyleOne}>客户评价</span>
                  <span style={titleStyleThree}>
                    每一条评价,都是温馨无价的财富
                  </span>
                </p>
              </a>
            </div>
            <div
              className="au-rt-right"
              style={{
                display: `flex`,
                flex: `3 1 0`,
                backgroundColor: `#f4b63f`,
                cursor: `pointer`,
              }}
            >
              <a
                style={{
                  display: `flex`,
                  flexDirection: `column`,
                  alignItems: `flex-start`,
                }}
              >
                <p
                  style={{
                    display: `flex`,
                    flexDirection: `column`,
                    alignItems: `flex-start`,
                    paddingLeft: `50px`,
                    paddingTop: `20px`,
                  }}
                >
                  <span style={titleStyleOne}>关于我们</span>
                  <span style={titleStyleThree}>了解并信任我们,</span>
                  <span style={titleStyleThree}>
                    选择悦禾旅游,您身边的旅行专家
                  </span>
                  <span
                    style={{ fontSize: `26px`, textTransform: `uppercase` }}
                  >
                    about Us
                  </span>
                </p>
                <p style={{ paddingLeft: `50px` }}>点击了解温馨假日旅游</p>
              </a>
            </div>
          </div>
          <div className="au-rightBottom" style={aboutUsRightBottom}>
            {" "}
            <div
              className="au-rb-left"
              style={{
                display: `flex`,
                flex: `3 1 0`,
                backgroundColor: `#5dcdc1`,
                cursor: `pointer`,
              }}
            >
              <a
                style={{
                  display: `flex`,
                  flexDirection: `column`,
                  alignItems: `flex-start`,
                  paddingLeft: `50px`,
                  paddingTop: `20px`,
                }}
              >
                <p
                  style={{
                    display: `flex`,
                    flexDirection: `column`,
                    alignItems: `flex-start`,
                  }}
                >
                  <span style={titleStyleOne}>省心服务</span>
                  <span style={titleStyleThree}>
                    每一次,悦禾旅游都将竭尽为您服务
                  </span>
                  <span style={titleStyleThree}>让旅行变的更加轻松</span>
                  <span
                    style={{ fontSize: `26px`, textTransform: `uppercase` }}
                  >
                    Hassle free services
                  </span>
                </p>
                <p>点击了省心服务</p>
              </a>
            </div>
            <div
              className="au-rb-right"
              style={{
                display: `flex`,
                flex: `1 1 0`,
                backgroundColor: `#565a5d`,
                flexDirection: `column`,
                alignItems: `center`,
              }}
            >
              <a>
                <p
                  style={{
                    display: `flex`,
                    flexDirection: `column`,
                    paddingTop: `20px`,
                    alignItems: `flex-start`,
                  }}
                >
                  <span style={titleStyleOne}>客服服务</span>
                  <span style={titleStyleThree}>
                    温馨假期旅游客服24x7小时,为您待命
                  </span>
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
